/* eslint-disable simple-import-sort/imports */
"use client";
import Prism from "prismjs";

import "prismjs/components/prism-jsx";
import "prismjs/themes/prism-tomorrow.css";

import { Check, Copy } from "lucide-react";

import React, { useState } from "react";

import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";

interface CodeDisplayProps {
  code: string;
}

export default function CodeDisplay({ code }: CodeDisplayProps) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // Highlight the code using Prism.js
  const highlightedCode = Prism.highlight(code, Prism.languages.jsx, "jsx");

  return (
    <Card className="relative overflow-hidden">
      <Button
        variant="ghost"
        size="icon"
        className="absolute right-2 top-2 z-10"
        onClick={copyToClipboard}
      >
        {copied ? <Check className="size-4" /> : <Copy className="size-4" />}
      </Button>
      <pre className="overflow-x-auto p-4">
        <code dangerouslySetInnerHTML={{ __html: highlightedCode }} />
      </pre>
    </Card>
  );
}
