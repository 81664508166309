"use client";

import { useEffect, useState } from "react";

type Light = "red" | "yellow" | "green";

export default function TrafficLight() {
  const [light, setLight] = useState<Light>("red");

  useEffect(() => {
    const timer = setTimeout(() => {
      setLight((currentLight) => {
        switch (currentLight) {
          case "red":
            return "green";
          case "green":
            return "yellow";
          case "yellow":
            return "red";
          default:
            return currentLight;
        }
      });
    }, getLightDuration(light) * 1000);

    return () => clearTimeout(timer);
  }, [light]);

  const getLightDuration = (currentLight: Light) => {
    if (currentLight === "red") {
      return 7;
    }
    if (currentLight === "green" || currentLight === "yellow") {
      return 5;
    }
    return 0;
  };

  return (
    <div className="p-20">
      <div className="mt-10 flex flex-col gap-5 border-2 border-gray-800 p-10 shadow-xl">
        <div
          className={`size-28 border border-gray-800 shadow-xl ${
            light === "red" && "bg-red-500"
          } rounded-full`}
        ></div>
        <div
          className={`size-28 border border-gray-800 shadow-xl ${
            light === "yellow" && "bg-yellow-500"
          } rounded-full`}
        ></div>
        <div
          className={`size-28 border border-gray-800 shadow-xl ${
            light === "green" && "bg-green-500"
          } rounded-full`}
        ></div>
      </div>
    </div>
  );
}
