"use client";

import { KeyboardEvent, useEffect, useState } from "react";

import { Button } from "@/components/ui/button";

const GRID_SIZE = 20;
const INITIAL_DIRECTION: Direction = "RIGHT";

type Direction = "UP" | "DOWN" | "LEFT" | "RIGHT";

type Point = {
  x: number;
  y: number;
};

export default function SnakeGame() {
  const [snake, setSnake] = useState<Point[]>([
    { x: 2, y: 0 },
    { x: 1, y: 0 },
    { x: 0, y: 0 },
  ]);
  const [food, setFood] = useState<Point>({ x: 0, y: 0 });
  const [direction, setDirection] = useState<Direction>(INITIAL_DIRECTION);
  const [gameOver, setGameOver] = useState<boolean>(false);

  useEffect(() => {
    const moveSnake = (): void => {
      const newSnake = [...snake];
      const head = { ...newSnake[0] };

      switch (direction) {
        case "UP":
          head.y -= 1;
          break;
        case "DOWN":
          head.y += 1;
          break;
        case "LEFT":
          head.x -= 1;
          break;
        case "RIGHT":
          head.x += 1;
          break;
        default:
          break;
      }

      // Check if game over
      if (
        head.x < 0 ||
        head.x >= GRID_SIZE ||
        head.y < 0 ||
        head.y >= GRID_SIZE ||
        newSnake.some((segment) => segment.x === head.x && segment.y === head.y)
      ) {
        setGameOver(true);
        return;
      }

      newSnake.unshift(head);

      // Check if snake eats food
      if (head.x === food.x && head.y === food.y) {
        generateFood();
      } else {
        newSnake.pop();
      }

      // Update the snake state
      setSnake(newSnake);
    };
    if (!gameOver) {
      const interval = setInterval(moveSnake, 60); // Adjust snake speed here
      return () => clearInterval(interval);
    }
  }, [snake, direction, gameOver, food.x, food.y]);

  useEffect(() => {
    const initGame = (): void => {
      generateFood();
    };

    initGame();
  }, []);

  const generateFood = (): void => {
    const x = Math.floor(Math.random() * GRID_SIZE);
    const y = Math.floor(Math.random() * GRID_SIZE);
    setFood({ x, y });
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "ArrowUp" && direction !== "DOWN") {
      setDirection("UP");
    }
    if (event.key === "ArrowDown" && direction !== "UP") {
      setDirection("DOWN");
    }
    if (event.key === "ArrowLeft" && direction !== "RIGHT") {
      setDirection("LEFT");
    }
    if (event.key === "ArrowRight" && direction !== "LEFT") {
      setDirection("RIGHT");
    }
  };

  const resetGame = () => {
    setGameOver(false);
    setSnake([
      { x: 2, y: 0 },
      { x: 1, y: 0 },
      { x: 0, y: 0 },
    ]);
    generateFood();
  };

  return (
    <div className="p-20">
      <div
        className="mt-20 flex items-center justify-center"
        onKeyDown={handleKeyPress}
        tabIndex={0}
        autoFocus
      >
        {gameOver && (
          <div
            className="
            absolute flex items-center justify-center 
            text-4xl font-bold text-red-600"
          >
            Game Over!
          </div>
        )}
        <div className="grid border border-black  bg-white">
          {Array.from({ length: GRID_SIZE }).map((_, y) => (
            <div key={y} className="flex">
              {Array.from({ length: GRID_SIZE }).map((_, x) => (
                <div
                  key={x}
                  className={`size-5 border border-gray-200 
                ${
                  snake.some((segment) => segment.x === x && segment.y === y) &&
                  "bg-green-500"
                }
                ${food.x === x && food.y === y && "bg-red-500"}
                `}
                ></div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <Button
        onClick={resetGame}
        variant="ghost"
        className="mt-2 bg-secondary hover:bg-orange-300"
      >
        Restart
      </Button>
    </div>
  );
}
