"use client";

import Image from "next/image";
import { useEffect, useState } from "react";

import { Button } from "@/components/ui/button";

const generateDeck = () => {
  const memoryCards = [
    "dwarf",
    "orc-connector",
    "elf",
    "orcish-ai-nextjs-framework",
    "orcishcity",
    "orcishlogo",
    "orcishmage",
    "textualgames",
  ];

  const deck = [...memoryCards, ...memoryCards];
  return deck.sort(() => Math.random() - 0.5);
};

export default function MemoryGame() {
  const [cards, setCards] = useState<string[]>(generateDeck());
  const [flipped, setFlipped] = useState<number[]>([]);
  const [solved, setSolved] = useState<number[]>([]);

  useEffect(() => {
    const checkForMatch = () => {
      const [first, second] = flipped;

      if (cards[first] === cards[second]) {
        setSolved([...solved, ...flipped]);
      }
      setFlipped([]);
    };

    if (flipped.length === 2) {
      const timeout = setTimeout(() => {
        checkForMatch();
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [cards, flipped, solved]);

  const handleClick = (index: number) => {
    if (!flipped.includes(index) && flipped.length < 2) {
      setFlipped([...flipped, index]);
    }
  };

  const gameOver = solved.length === cards.length;

  const resetGame = () => {
    setCards(generateDeck());
    setFlipped([]);
    setSolved([]);
  };

  return (
    <div className="p-20 text-center">
      {gameOver && <h2 className="p-5 text-green-500">You WON! Congrats!</h2>}
      <div className="mt-5 grid grid-cols-4 gap-5">
        {cards.map((card, index) => (
          <div
            className={`flex size-28 cursor-pointer items-center justify-center bg-slate-200 text-4xl font-bold text-black transition-transform duration-300 hover:bg-gray-300 ${
              flipped.includes(index) || solved.includes(index)
                ? "rotate-180"
                : ""
            }`}
            key={index}
            onClick={() => handleClick(index)}
          >
            {flipped.includes(index) || solved.includes(index) ? (
              <Image
                className="rotate-180"
                src={`/memory-cards/${card}.webp`}
                fill
                alt={`Memory Card ${card}`}
              />
            ) : (
              "?"
            )}
          </div>
        ))}
      </div>
      <Button
        onClick={resetGame}
        variant="ghost"
        className="mt-2 bg-secondary hover:bg-orange-300"
      >
        Restart
      </Button>
    </div>
  );
}
